import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'

const ACCESS_TOKEN_NAME = 'block-backend-at'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * If we are administrators we can do stuff.
   */
  admin?: boolean

  /**
   * The user that actually block other peoples bankid.
   */
  user?: boolean

  /**
   * Set by the HttpIncerceptor when a token is ready to be used
   */
  interceptorReady?: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean
}


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  public configState: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false})

  public currentUser$: Observable<SparbankenUser>
  private pCurrentUser$: ReplaySubject<SparbankenUser> = new ReplaySubject<SparbankenUser>(1)
  private currentConfig: SpbConfiguration = {ready: false}

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.isLoggedIn()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token as string)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const payload = JSON.parse(atob(token.split('.')[1]))
    this.currentConfig = {
      token: payload.exp > new Date().getTime() ? token : this.resetToken(),
      admin: payload.roles.indexOf('blockBankIdAdmin') !== -1,
      user: payload.roles.indexOf('blockBankIdUser') !== -1,
      ready: false
    }
    this.checkIfReady()
    this.configState.next(this.currentConfig)
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): undefined {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false}
    this.currentConfig.token = undefined
    this.configState.next(this.currentConfig)
    return undefined
  }

  public setCurrentUser(user: SparbankenUser): void {
    this.pCurrentUser$.next(user)
  }

  /**
   * Set the ready state of the configuration
   */
  public checkIfReady(): void {
    this.currentConfig.ready =
      (this.currentConfig.admin === true || this.currentConfig.user === true)
  }

  public getAccessToken(): string | undefined | null {
    return this.currentConfig.token
  }
}
