import {Component, Inject, NgZone, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {EMPTY} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {WINDOW} from '../../application/window.provider'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public bankIdUrl = environment.authServiceUrl

  /**
   * The constructor
   *
   * @param configService - Holds the logged in state and tells ut what to show.
   * @param router - Needed to navigate on menu actions from code.
   * @param singleSignOnService
   * @param ngZone - The zone is needed for safe navigation.
   * @param injectedWindow - If we navigate of app we need this.
   */
  constructor(
    public configService: ConfigService,
    private router: Router,
    private singleSignOnService: SingleSignOnService,
    private ngZone: NgZone,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   * A navigation function that can navigate internally or externally
   *
   * @param link - The place to go, start with https:// to navigate externally.
   */
  public navigate(link: string): void | any {
    if (link.indexOf('https://') !== -1) {
      this.injectedWindow.location.href = link
    } else {
      return this.ngZone.run(() => this.router.navigate([link]))
    }
  }

  public ngOnInit(): void {
    this.singleSignOnService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.logout()
          return EMPTY
        })
      )
      .subscribe(
        {next: (accessToken: string) => this.configService.setToken(accessToken)})
  }

  public logout(): void {
    this.configService.resetToken()
    this.singleSignOnService.deleteToken(this.bankIdUrl)
      .subscribe({
        next: () => {
          this.configService.resetToken()
          return this.router.navigate(['login'])
        }
      })
  }
}
